<template>
  <div class="contents dashboard" v-loading="loading">
    <div class="title flexB">
      <h2>대시보드</h2>
    </div>
    <div class="box one filter">
      <div class="buttonWrap refresh">
        <p>{{ moment().format("YYYY.MM.DD HH:mm") }}</p>
        <button
          class="basic"
          @click="getCount"
          :class="{ loading: inactive == true }"
        >
          새로고침
          <img src="@/assets/images/icon_svg/refresh.svg" alt="새로고침" />
        </button>
      </div>
      <div class="contBox">
        <h2>가입자 수</h2>
        <div class="contWrap first flex">
          <div class="cont">
            <h4>
              <span class="material-icons"> account_circle </span> 전체 가입자
            </h4>
            <h3>{{ totalUser }}<span>명</span></h3>
          </div>

          <div class="cont">
            <h4>
              <span class="material-icons"> account_circle </span> 월별 가입자
            </h4>
            <h3>{{ monthJoinUser }}<span>명</span></h3>
          </div>
          <div class="cont">
            <h4>
              <span class="material-icons"> account_circle </span> 일별 가입자
            </h4>
            <h3>{{ dayJoinUser }}<span>명</span></h3>
          </div>
          <div class="cont">
            <h4>
              <span class="material-icons"> account_circle </span> 탈퇴 회원
            </h4>
            <h3>{{ dropUser }}<span>명</span></h3>
          </div>
        </div>
      </div>
      <div class="contBox"></div>
      <div class="contBox">
        <h2>게시글 수</h2>
        <div class="contWrap flex">
          <div class="cont">
            <h4>
              <span class="material-icons"> account_circle </span> 전체 등록된
              게시글 수
            </h4>
            <h3>{{ totalPost }}<span>개</span></h3>
          </div>
          <div class="cont">
            <h4>
              <span class="material-icons"> account_circle </span> 이번달 등록된
              게시글 수
            </h4>
            <h3>{{ monthPost }}<span>개</span></h3>
          </div>
          <div class="cont">
            <h4>
              <span class="material-icons"> account_circle </span> 신규 등록된
              게시글 수
            </h4>
            <h3>{{ dayPost }}<span>개</span></h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { format } from "@/mixins/format";
import { fetchDashboard } from "@/api/admin";

export default {
  mixins: [format],
  name: "dashboard",
  data() {
    return {
      moment: moment,
      dashboard: [],
      barData: [],
      category: [],
      loading: false,
      inactive: false,
      totalUser: "",
      monthJoinUser: "",
      dayJoinUser: "",
      dropUser: "",
      totalPost: "",
      monthPost: "",
      dayPost: "",
      totalArticle: "",
      monthArticle: "",
      dayArticle: "",
    };
  },
  created() {
    this.$store.dispatch("SET_NAVBAR", "0");
  },
  mounted() {
    // this.loading = true;
    this.getCount();
    this.yearOption =
      moment().format("YYYY") - 2023 == 0
        ? 1
        : moment().format("YYYY") - 2023 + 1;
  },
  methods: {
    getCount() {
      this.inactive = true;
      fetchDashboard().then((res) => {
        if (res.data.status == 200) {
          this.totalUser = res.data.totalUser;
          this.monthJoinUser = res.data.monthJoinUser;
          this.dayJoinUser = res.data.dayJoinUser;
          this.dropUser = res.data.dropUser;
          this.totalPost = res.data.totalPost;
          this.monthPost = res.data.monthPost;
          this.dayPost = res.data.dayPost;
          this.totalArticle = res.data.totalArticle;
          this.monthArticle = res.data.monthArticle;
          this.dayArticle = res.data.dayArticle;
          this.loading = false;
          this.inactive = false;
        }
      });
    },
  },
};
</script>
